<template>
    <div></div>
</template>

<script>
    export default {
        name: "OffCanvasSets",
        mounted() {
            this.$emit('toggleMenu','secondary', false)
        }
    }
</script>
